<template>
  <div>
    <div
      class="modalScroll d-flex flex-column align-items-center"
      style="width: auto; height: auto"
    >
      <div
        class="mb-1 d-flex justify-content-center"
        style="font-size: 1.9rem; font-weight: 500;"
      >
        {{ $t("Performance Grade") }}
        <!-- perf id {{ performance }}
        user id {{ user }} -->
        <br>
      </div>

      <div
        class="p-1"
        style=""
      >

        <b-form-radio-group
          v-model="final"
          :options="gradeList"
          stacked
          class="styleFont"
        />
      </div>

      <b-row class="d-flex justify-content-center">
        <button
          class="btn btn-close m-1 saj-subtitle"
          aria-label="Close modal"
          style="
          color: white;
          background: #ff0000;
        "
          @click="close()"
        >
          {{ $t("Back") }}
        </button>
        <button
          class="btn m-1 saj-subtitle"
          style="
          color: white;
          background: #0b103c !important;
          border-color: #0b103c !important;
        "
          @click="updateEvaluation()"
        >
          {{ $t("Save") }}
        </button>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BFormRadioGroup,

} from "bootstrap-vue"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BFormRadioGroup,

  },
  props: {
    employee: {
      type: Number,
      default: null,
    },
    performance: {
      type: Number,
      default: null,
    },
    evaluation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      final: null,
      gradeList: [],

    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  mounted() {
    this.getPerformanceGrade()
    // console.log('emp', this.employee)
    this.final = this.evaluation
  },
  methods: {
    close() {
      this.$emit("close")
    },

    getPerformanceGrade(){
      // const defautlGrade = [{ text: `${this.$t('Choose Performance Grade')}`, value: "", disabled: true }]
      this.$axios.get(`${this.$baseUrl}/performance_grades/get_by_subsidiary?subsidiary_id=${this.employee}`).then(response => {
        const allGrade = response.data.data
        // console.log(allGrade)

        this.gradeList = allGrade.map(x => (
          `${this.$i18n.t(x.grade)}`
        ))
        // this.allGrade.forEach(a => {
        //   this.gradeList.push(a.grade)
        // })
      })
    },

    updateEvaluation() {
      // console.log("hereeeeee", this.final)
      const data = new FormData()
      data.append(`performance_id`, this.performance)
      data.append(`performance_grade`, this.final)

      this.$axios
        .post(`${this.$baseUrl}/final_evaluation/update`, data)
        .then(() => {
          setTimeout(() => {
            this.$emit('update-evaluation')
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: `${this.$t('Final evaluation successfully updated')}.`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
            this.close()
          }, 1000)
        }).catch(error => {
        // console.log(error.response)
        // this.disableInput = true
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: error.response.data.message,
                icon: "XIcon",
                variant: "danger",
                iconBg: '#e80202',
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
          this.$emit("close")
        })
    },
  },
}
</script>
<style>
.styleFont {
    font-size: 2.5rem !important;
}
.custom-checkbox .custom-control-label, .custom-radio .custom-control-label {
    font-size: 1.5rem;
    position: static;
}

</style>
