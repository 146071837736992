<template>
  <div class="p-1">
    <div class="d-flex justify-content-center">
      <p style="font-size: 1.7rem;">
        {{ $t("List of Project(s) Involved") }}
      </p>
      <!-- {{ projectList }} -->
    </div>
    <b-table
      show-empty
      :items="projectList"
      :fields="fields"
      responsive=""
      bordered
      class="mt-1 mb-1 saj-subtitle"
    >
      <!-- A custom formatted column -->
      <template #head()="data">
        <span
          class="saj-title"
          style=""
        >{{ $t(data.label) }}</span>
      </template>

      <template #empty="">
        <h4 style="text-align: center; font-style: italic">
          {{ $t('There are no records to show') }}
        </h4>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(name)="data">
        {{ data.item.name }}
      </template>
      <template #cell(activity)="data">
        {{ data.item.activity }}
      </template>
      <template #cell(progress)="data">
        <div
          class="d-flex justify-content-center"
        >
          {{ data.item.progress + "%" }} </div>
      </template>
      <template #cell(remarks)="data">
        <div class="d-flex justify-content-center">
          {{ $t(data.item.remarks === null ? '-' : data.item.remarks) }}
        </div>
        <!-- {{ data.item.id }} -->
        <!-- <b-card class="p-1 mt-1">
          <b-row
            class="saj-text justify-content-start"
            style=""
          >
            <div
              v-if="isView"
              class="d-flex"
              @click="viewTimeline(data), memberId = projectList[data.index].id , viewHistory()"
            >
              <h3
                class="saj-title font-weight-bolder"
                style="cursor:pointer"
              >
                {{ $t("Timeline") }}
              </h3>
              <feather-icon
                v-if="!projectList[data.index].isTimeline"
                v-b-tooltip.hover.bottom="$t('Open')"
                icon="ChevronDownIcon"
                style="cursor:pointer"
                size="26"
                class="ml-1"
              />
            </div>
            <div
              class="d-flex"
            >
              <feather-icon
                v-if="projectList[data.index].isTimeline"
                v-b-tooltip.hover.bottom="$t('Close')"
                icon="ChevronUpIcon"
                style="cursor:pointer"
                size="26"
                class="ml-1"
                @click="closeTimeline(data), clear()"
              />
            </div>
          </b-row>
          <b-row
            class=" saj-text justify-content-start ml-1"
            style=""
          >
            <div
              v-if="projectList[data.index].isTimeline"
            >
              <Timeline
                :timeline-items="timelineItems"
                :message-when-no-items="messageWhenNoItems"
                order="desc"
              />
            </div>
            <div
              v-if="!projectList[data.index].isTimeline"
            />
          </b-row>
        </b-card> -->
      </template>
      <template #cell(assigned_date)="data">
        <div class="d-flex justify-content-center">
          {{ data.item.assigned_date !== null ? DateChanged(data.item.assigned_date) : '-' }}
        </div>
      </template>

      <template #cell(due_date)="data">
        <div
          class="d-flex justify-content-center"
        >
          {{ data.item.due_date !== null ? DateChanged(data.item.due_date) : '-' }}
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import moment from "moment"
import {
  BTable,
  // BCard,
  // BRow,
  VBTooltip,
} from "bootstrap-vue"
// import Timeline from 'timeline-vuejs'

export default {
  components: {
    BTable,
    // Timeline,
    // BCard,
    // BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {

    userId: {
      type: Number,
      default: null,
    },
    cycleYear: {
      type: String,
      default: null,
    },

  },
  data(){
    return {
      selectedYear: null,
      projectList: [],
      fields: [
        { key: 'project', label: 'Project Name', thClass: 'text-center' },
        { key: 'activity', label: 'Activity', thClass: 'text-center' },
        { key: 'progress', label: 'Status', thClass: 'text-center' },
        { key: 'remarks', label: 'Remarks', thClass: 'text-center' },
        {
          key: 'assigned_date', label: 'Assigned Date', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'due_date', label: 'Due Date', thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      messageWhenNoItems: `${this.$t('Timeline not available')}`,
      // timelineItems: [
      //   {
      //     from: new Date(2018, 7, 4),
      //     title: 'Name',
      //     description:
      //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.',
      //     showDayAndMonth: true,
      //   },
      //   {
      //     from: new Date(2016, 1, 3),
      //     title: 'Name',
      //     description:
      //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.',
      //     showDayAndMonth: true,
      //   },
      //   {
      //     from: new Date(2016, 6, 2),
      //     title: 'Name',
      //     description:
      //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.',
      //     showDayAndMonth: true,
      //   },
      //   {
      //     from: new Date(2012, 1, 1),
      //     title: 'Name',
      //     description:
      //     'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius earum architecto dolor, vitae magnam voluptate accusantium assumenda numquam error mollitia, officia facere consequuntur reprehenderit cum voluptates, ea tempore beatae unde.',
      //     showDayAndMonth: true,
      //   },
      // ],
      timelineItems: [],
      historyList: null,
      showTimeline: false,
      isView: true,
      memberId: null,
    }
  },
  mounted(){
    this.customFormatter()
    // this.viewHistory()
  },
  methods: {

    customFormatter(date) {
      this.selectedYear = moment(date).format('YYYY')
      this.getProject()
    },

    getProject(){
      const params = new URLSearchParams()

      params.append('user_id', this.userId)
      params.append('year', this.cycleYear)

      this.$axios.get(`${this.$baseUrl}/getProjectManagementByUser`, { params }).then(response => {
        const info = response.data.data
        // console.log('info', info)
        this.projectList = info.map(x => ({
          activity: x.activity,
          progress: x.progress,
          remarks: x.remarks,
          assigned_date: x.assigned_date,
          due_date: x.due_date,
          project: x.details.title,
          id: x.id,
          isTimeline: false,
        }))
      })
    },

    viewHistory(){
      // console.log(this.memberId)
      this.$axios.get(`${this.$baseUrl}/getProjectManagementHistory?id=${this.memberId}`).then(response => {
        // this.historyList = response.data.data

        // this.timelineItems = response.data.data
        for (let i = 0; i < response.data.data.length; i += 1) {
          if (response.data.data.length !== null){
            this.timelineItems.push({
              from: new Date(response.data.data[i].created_at),
              title: `Activity: ${response.data.data[i].activity}`,
              description: `Progress: ${response.data.data[i].progress}%`,
              showDayAndMonth: true,
            })
          }
        }

        // console.log('timeline list', this.timelineItems)
      })
    },

    clear(){
      this.timelineItems = []
    },

    DateChanged(date) {
      const newDay = moment(date, "").format("D-MM-yyyy")
      return newDay
    },
    showTL(){
      // console.log('masuk')
      // this.projectList[data.index].isTimeline = true
      this.showTimeline = true
      // this.isEdit = true
    },
    viewTimeline(data){
      this.projectList[data.index].isTimeline = true
      this.showTL()
    },
    closeTimeline(data){
      this.projectList[data.index].isTimeline = false
    },

  },
}
</script>
