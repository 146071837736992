<template>
  <div class="">
    <a
      href="ss"
      download
    />
    <b-card
      no-body
      class="mr-1"
      style="height: 100%; overflow-x: hidden;"
    >
      <div v-if="roles.isTopManagement && division !== null">
        <!-- Head of Division:  {{ division }} -->
      </div>
      <div class="pl-1 pt-1 ">
        <span class="saj-title">
          {{ $t("Search Filter") }}
          <!-- {{ roles }} -->
          <!-- {{ approveList }} -->
        </span>
      </div>
      <div class="row align-items-end px-1 mb-1">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Category") }}:</label>
          <b-form-select
            v-model="selectedCategory"
            :options="categoryList"
            :value="categoryList"
            size="sm"
            class="saj-text"
          />
        </b-col>
        <!-- if admin -->
        <!-- <template v-if="roles.selectedRole === 1"> -->
        <!-- <b-col
          v-if="roles.selectedRole === 1"
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Subsidiary") }}:</label>
          <v-select
            v-model="selectedSub"
            class="saj-text select-size-sm"
            style="background: white"
            :placeholder="$t('Choose Subsidiary')"
            :options="subList"
            :reduce="sub => sub.value"
            label="text"
            :clearable="false"
            :disabled="selectedCategory === null"
            @input="roles.selectedRole === 1 && selectedSub !== null ? getData() : ''"
          />
        </b-col> -->
        <!-- <b-col
          v-if="roles.selectedRole !== 4"
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Work Location") }}:</label>
          <v-select
            v-model="selectedWork"
            class="saj-text select-size-sm"
            style="background: white"
            :placeholder="$t('Choose Work Location')"
            :options="WorkList"
            :reduce="work => work.value"
            label="text"
            :disabled="roles.selectedRole !== 1 ? selectedCategory === null : selectedSub === null"
          />
        </b-col> -->
        <!-- Department others -->
        <!-- <b-col
          v-if="roles.selectedRole === 1 || roles.selectedRole === 6"
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Department") }}:</label>
          <v-select
            v-model="selectedDepartment"
            class="saj-text select-size-sm"
            style="background: white"
            :placeholder="$t('Choose Department')"
            :options="departmentList"
            :reduce="dept => dept.value"
            label="text"
            :disabled="roles.selectedRole !== 1 ? selectedCategory === null : selectedSub === null"
          />
        </b-col> -->
        <!-- Department for Head of Division -->
        <!-- <b-col
          v-if="division !== null && roles.selectedRole === 4"
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Department") }}:</label>
          <v-select
            v-model="selectedDepartment"
            class="saj-text select-size-sm"
            style="background: white"
            :placeholder="$t('Choose Department')"
            :options="deptList"
            :reduce="dept => dept.value"
            label="text"
            :disabled="roles.selectedRole !== 1 ? selectedCategory === null : selectedSub === null"
          />
        </b-col> -->
        <!-- </template> -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Search Name") }}:</label>
          <b-form-input
            id="icons-search"
            v-model="searchname"
            :placeholder="$t('Search name')"
            size="sm"
            :disabled="selectedCategory === null"
          />
        </b-col>
        <b-col

          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Search Employee Number") }}:</label>
          <b-form-input
            id="icons-search"
            v-model="searchEmpNo"
            :placeholder="$t('Search Employee Number')"
            size="sm"
            :disabled="selectedCategory === null"
          />
        </b-col>
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Approval Status") }}:</label>
          <b-form-select
            v-model="selectedStatus"
            :options="statusList"
            :value="statusList"
            :disabled="roles.isAdmin ? (selectedSub === null) : false"
            size="sm"
            class="saj-text"
          />
        </b-col> -->
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Performance Grade") }}:</label>
          <b-form-select
            v-model="selectedGrade"
            :options="gradeList"
            :value="gradeList"
            :disabled="selectedCategory === null"
            size="sm"
            class="saj-text"
          />
        </b-col> -->
        <div
          v-if="roles.isAdmin"
          class="d-flex mt-1"
        >
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="d-flex justify-content-center"
              style=""
              :disabled="selectedCategory === null || subId === null"
              @click="getSummaryReport('search'), currentPage = 1"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="saj-title d-flex justify-content-center"
              block
              style="
          "
              @click="clearButton()"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
            </b-button>
          </b-col>
        </div>
        <div
          v-else
          class="d-flex mt-1"
        >
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="d-flex justify-content-center"
              :style="roles.isHR || roles.isTopManagement ? {'margin-top': '15px'} : {}"
              :disabled="selectedCategory === null"
              @click="getSummaryReport('search'), currentPage = 1"
            >

              <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="saj-title d-flex justify-content-center"
              block
              :style="roles.isHR || roles.isTopManagement ? {'margin-top': '15px'} : {}"
              @click="clearButton()"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
            </b-button>
          </b-col>
        </div>

      </div>
      <b-overlay
        :show="show"
        rounded="sm"
        class="mt-4"
        style="height: 100%;"
      >

        <template #overlay>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
            <p class="mt-1">
              {{ $t('Fetching Data') }}...
            </p>
          </div>

        </template>
        <div
          v-if="showReport"
          class="p-1"
        >
          <b-table
            sticky-header="500px"
            style="max-height:350px;"
            :items="finalData"
            :fields="allColumnField"
            responsive
            bordered
            show-empty
          >

            <template #cell(index)="data">
              <div class="saj-subtitle d-flex justify-content-center">
                {{ currIdx === 1 ? data.index + 1 : (data.index + 1)+((currIdx-1) *perPage) }}
              </div>
              <!-- {{ allColumnField }} -->
            </template>
            <template #empty="">
              <h4 style="text-align: center; font-style: italic">
                {{ $t('There are no records to show') }}
              </h4>
            </template>
            <template #head()="data">
              <span
                class="saj-subtitle"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <template #cell(hod_evaluation)="data">
              <div
                class=""
                style="color: #464e5f; text-align: center"
              >
                <p class="mt-1">
                  {{ data.item.hod_evaluation }}
                </p>
                <feather-icon
                  v-if="roles.selectedRole === 5"
                  v-b-modal.hod-evaluation
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  style=" width: 1.5rem; height: 1.5rem; color: orange"
                  class=""
                  @click="tempSubs = data.item.subsidiary_id, tempPerf = data.item.performance_id, tempEval = data.item.hod_evaluation"
                />
              </div>
            </template>
            <template #cell(committee_evaluation)="data">
              <div
                class=""
                style="color: #464e5f; text-align: center"
              >
                <p class="mt-1">
                  {{ data.item.final_evaluation }}
                </p>
                <feather-icon
                  v-if="roles.selectedRole === 1"
                  v-b-modal.performance-grade
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  style=" width: 1.5rem; height: 1.5rem; color: orange"
                  class=""
                  @click="tempSubs = data.item.subsidiary_id, tempPerf = data.item.performance_id, tempEval = data.item.final_evaluation"
                />
              </div>
            </template>
            <template #cell(project_no)="data">
              {{ data.item.project_no }}
              <p
                v-b-modal.view-project
                class="mt-1"
                style="text-decoration: underline; color: #5555ff;"
                @click="selectedUser = data.item.user_id, cycleYear = data.item.cycle_year"
              >
                {{ $t("View Project") }}
              </p>
            </template>
            <template #cell(total_score)="data">
              {{ data.item.total_score }}
              <p
                v-b-modal.view-score
                class="mt-1"
                style="text-decoration: underline; color: #5555ff;"
                @click="tempPerf = data.item.performance_id"
              >
                {{ $t("Score Logs") }}
              </p>
            </template>
          </b-table>
          <b-modal
            id="hod-evaluation"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <hodEvaluation
              :employee="tempSubs"
              :performance="tempPerf"
              :evaluation="tempEval"
              @close="$bvModal.hide('hod-evaluation')"
              @update-evaluation="getSummaryReport('search'), reloadTable()"
            />
          </b-modal>

          <b-modal
            id="performance-grade"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <perf-grade
              :employee="tempSubs"
              :performance="tempPerf"
              :evaluation="tempEval"
              @close="$bvModal.hide('performance-grade')"
              @update-evaluation="getSummaryReport('search'), reloadTable()"
            />
          </b-modal>

          <b-modal
            id="view-project"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
            size="xl"
          >
            <viewProject
              :user-id="selectedUser"
              :cycle-year="cycleYear"
            />
          </b-modal>

          <b-modal
            id="view-score"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
            size="md"
          >
            <viewScore
              :perf-id="tempPerf"
            />
          </b-modal>

          <b-row
            v-if="finalData.length > 0"
            class="ml-1 mr-1"
          >
            <b-col>
              <span
                class="saj-text"
                align="start"
              >
                {{ $t("Showing") }} {{ from }} {{ $t("to") }} {{ to }} {{ $t("of") }} {{ rows }} {{ $t("entries") }}
              </span>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-end">
                <i
                  style="margin-right: 5px"
                  class="fa-solid fa-angles-left saj-page"
                  @click="currentPage = 1, getSummaryReport('search')"
                />
                <i
                  class="fa-solid fa-angle-left saj-page"
                  style=""
                  @click="currentPage !== 1 ? currentPage-- : currentPage === 1, getSummaryReport('search')"
                />
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Page')"
                  :rules="{
                    required,
                    max_value:lastPage,
                    min_value:1,
                  }"
                >
                  <b-form-input
                    v-model="currentPage"
                    class="p-0 text-center"
                    type="number"
                    style="width: 35px; height: 35px; background-color: red; color: white; border-radius: 20px; margin-right: 5px; margin-left: 5px;"
                    @change="currentPage > 0 && currentPage < lastPage + 1 ? getSummaryReport('search'): ''"
                  />
                  <small
                    class="text-danger"
                    style="color: #EA5455 !important;
                          position: absolute;
                          white-space: nowrap;
                          right: 25px;
                        "
                  >{{ errors[0] }}</small>
                </validation-provider>
                <i
                  class="fa-solid fa-angle-right saj-page"
                  style=""
                  @click="currentPage < lastPage ? currentPage++ : currentPage === rows, getSummaryReport('search')"
                />
                <i
                  class="fa-solid fa-angles-right saj-page"
                  style="margin-left: 5px"
                  @click="currentPage = lastPage, getSummaryReport('search')"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-overlay>

    </b-card>
  </div>
</template>
<script>
// import vSelect from 'vue-select'
import {
  BTable,
  // BTr, BTh,
  BCard,
  BCardHeader,
  // BCardBody,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  VBPopover,
  BPopover,
  BFormInput,
  BOverlay,
  BSpinner,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'
import VueExcelXlsx from "vue-excel-xlsx"
import Vue from "vue"
import Ripple from "vue-ripple-directive"
import { mapGetters } from 'vuex'
// import _ from 'lodash'
import SAJToast from "@/component/saj-toastification.vue"
import { ValidationProvider, extend } from 'vee-validate'
import {
  max_value,
  min_value,
} from 'vee-validate/dist/rules'
import { required } from '@validations'
// import EditEmployee from "@/component/hod/EditEmployee.vue"
// import ApproveEmployee from "@/component/hod/ApproveEmployee.vue"
// import RevertForm from "@/component/hod/RevertForm.vue"
// import RevertKra from "@/component/hod/RevertKra.vue"
// import AlertModal from '@/pages/alert-modal.vue'
import perfGrade from '@/views/pages/summary/PerformanceGrade.vue'
import hodEvaluation from '@/views/pages/summary/hod-evaluation-update.vue'
// import downloadPDF from "@/views/pages/summary/summary-report-pdf-modal.vue"
import viewProject from "@/views/pages/summary/view-project.vue"
import viewScore from "@/views/pages/summary/view-score.vue"

extend('max_value', max_value)
extend('min_value', min_value)

Vue.use(VueExcelXlsx)

export default {
  components: {
    // AlertModal,
    BTable,
    // BTh,
    // BTr,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    // BCardBody,
    BButton,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BPopover,
    BFormInput,
    BOverlay,
    BSpinner,
    ValidationProvider,
    BModal,
    // EditEmployee,
    // ApproveEmployee,
    // RevertForm,
    // RevertKra,
    // Template,
    perfGrade,
    // vSelect,
    // downloadPDF,
    hodEvaluation,
    viewProject,
    viewScore,
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {

    subId: {
      type: Number,
      default: null,
    },
    deptId: {
      type: Number,
      default: null,
    },
    grade: {
      type: String,
      default: null,
    },

  },

  data(){
    return {

      isInternal: false,
      tempEval: null,
      exporting: false,
      approvalStatus: [],
      userRole: [],
      jdkraSetting: null,
      execKRA: null,
      nonExecKRAMain: null,
      nonExecKRASide: null,
      approveList: [],
      tempUser: null,
      performanceId: null,
      tempPerf: null,
      tempSubs: null,
      currIdx: null,
      showReport: false,
      allWorkLocation: [],
      isNextData: true,
      show: false,
      additionalCol: 12,
      // maxDariEzra: 6,
      subsidiary_id: null,
      employeeData: null,
      jd_kra_status: null,
      core: 6,
      behaviour: 5,
      maxSub: null,
      apiData: null,
      searchname: null,
      searchEmpNo: null,
      companyID: '',
      selectedposition: '',
      selectedDistrict: '',
      selectedCategory: null,
      selectedFilter: "",
      selectedWork: null,
      division: null,
      rolesList: [],
      filterList: [],
      allFilter: [],
      allRoles: [],
      allDistrict: [],
      allEmpID: [],
      districtList: [],
      categoryList: [
        { text: `${this.$i18n.t('Choose Category')}`, value: null, disabled: true },
        { text: `${this.$i18n.t('Executive')}`, value: 'Executive' },
        { text: `${this.$i18n.t('Non Executive')}`, value: 'Non Executive' },
      ],
      statusList: [
        { text: `${this.$i18n.t('Choose Status')}`, value: null, disabled: true },
        { text: `${this.$i18n.t('Unsubmitted')}`, value: 'unsubmitted' },
        { text: `${this.$i18n.t('Submitted')}`, value: 'submit' },
        { text: `${this.$i18n.t('Agreed')}`, value: 'agree' },
        { text: `${this.$i18n.t('Supervisor Approved')}`, value: 'approved_sv' },
        { text: `${this.$i18n.t('Head of Department Approved')}`, value: 'approved_hod' },
        { text: `${this.$i18n.t('Human Resource Approved')}`, value: 'approved_hr' },
        { text: `${this.$i18n.t('Super Human Resource Approved')}`, value: 'approved_shr' },
      ],
      deptList: [],
      // empIDList: [],
      allWork: [],
      WorkList: [],
      subList: [],
      selectedSub: null,
      departmentList: [],
      selectedDepartment: null,
      selectedStatus: null,
      lang: null,
      errorMsg: [],
      errFields: [
        {
          key: 'line', label: 'Excel Row No', tdClass: 'text-center',
        },
        {
          key: 'errors', label: 'Error List', tdClass: 'text-left',
        },
      ],

      columns: [
        // { label: "Id", field: "employee_number" },
        // { label: "Name", field: "employee_name" },
        // { label: "Category", field: "category" },
        // { label: "District", field: "district" },
        // { label: "Kra/Jd", field: "jd_kra" },
        // { label: "Core", field: "competency_core" },
        // { label: "Behavioural", field: "competency_behaviour" },
        // { label: "Training & Development", field: "training" },
        // { label: "Contribution", field: "contribution" },
        // { label: "Total Score", field: "total_score" },
      ],

      allColumnField: [
        {
          key: 'index', label: 'No',
        },
        {
          key: 'employee_number', label: 'ID', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'employee_name', stickyColumn: true, label: 'Name', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'category', label: 'Category', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'grade', label: 'Grade', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'work', label: 'Work Location', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        // {
        //   key: 'jd_new', label: 'JD', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        // },
        // {
        //   key: 'jd_kra_total', label: 'KRA/JD', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        // },

      ],
      finalData: [],
      // currentPageSummaryReport: 0,
      currUserId: null,
      required,
      currentPage: 1,
      perPage: null,
      to: null,
      from: null,
      rows: null,
      lastPage: null,
      kraCol: null,
      krajdCol: 1,
      hod_evaluation: null,
      divisiondept: null,
      // userIsHOD: null,
      selectedUser: null,
      gradeList: [
        { text: `${this.$i18n.t('Choose Status')}`, value: null, disabled: true },
        { text: `${this.$i18n.t('Need Improvement')}`, value: 'Need Improvement' },
        { text: `${this.$i18n.t('Satisfactory')}`, value: 'Satisfactory' },
        { text: `${this.$i18n.t('Good')}`, value: 'Good' },
        { text: `${this.$i18n.t('Very Good')}`, value: 'Very Good' },
        { text: `${this.$i18n.t('Excellent')}`, value: 'Excellent' },
      ],
      selectedGrade: null,
      cycleYear: null,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.lang = this.$i18n.locale
      this.currentPage = this.currIdx
      // this.getSummaryReport()
      this.categoryList = this.categoryList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Category'),
          }
        }
        return x
      })
      this.statusList = this.statusList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Status'),
          }
        }
        return x
      })
      this.getAllDepartment()
      this.getAllSubsidiary()
    },
  },
  beforeMount() {

  },
  mounted() {
    this.lang = this.$i18n.locale
    this.currentUser()

    this.getAllRoles()
    this.getAllSubsidiary()

    if (this.roles.selectedRole !== 1){
      this.getWorkLocation()
      this.getAllDepartment()
    }

    // this.getSummaryReport()
  },
  methods: {
    clearButton() {
      this.selectedDistrict = ""
      this.selectedposition = ""
      this.selectedCategory = null
      this.searchname = null
      this.searchEmpNo = null
      this.selectedWork = null
      this.currentPage = 1
      this.finalData = []
      this.selectedDepartment = null
      this.selectedSub = null
      // this.getSummaryReport()
      this.showReport = false
      this.selectedStatus = null
      this.selectedGrade = null
    },

    currentUser(){
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(res => {
        // console.log('Current User', response)
        this.division = res.data.data.division
        // console.log('division', this.division)
        if (this.division !== null){
          this.getAllRoles()
          this.getMax()

          this.getAllSubsidiary()
          if (this.roles.selectedRole !== 1){
            this.getWorkLocation()
            this.getAllDepartment()
            this.getDepartmentDivision()
          }
        }
      })
    },

    getDepartmentDivision(){
      // console.log(this.user)
      this.$axios.get(`${this.$baseUrl}/divisions/get_all_divisions?division_id=${this.division}`).then(res => {
        // this.user = res.data.data
        // console.log(res, 'ini div')
        this.divisiondept = res.data.data
        this.getAllDepartments = this.divisiondept.departments
        const a = this.getAllDepartments.map(dept => ({
          value: dept.id,
          text: dept.name,
        }))

        this.deptList = this.deptList.concat(a)

        // console.log('list dept', this.deptList)
      })
    },

    getAllRoles() {
      this.$axios.get(`${this.$baseUrl}/roles/getAllRoles`).then(response => {
        this.allRoles = response.data.data

        this.allRoles.forEach(role => {
          this.rolesList.push(role.name)
        })
      })
    },

    getSummaryReport(search = 'no') { // filter and get all data
      // console.log('details user: ', this.userDetails)
      this.show = true

      let url = null
      if (this.division !== null && this.roles.selectedRole === 4){
        url = `/summary/head_of_division_report`
      } else {
        url = `/summary/summary_report`
      }

      const params = new URLSearchParams()
      if (this.deptId !== null) {
        params.append('department_id', this.deptId)
      }

      // else if (this.roles.selectedRole === 5) { // if HOD
      //   params.append('department_id', this.userDetails.department_id)
      // }

      if (this.selectedCategory !== null) {
        params.append('category', this.selectedCategory)
      }
      if (this.searchname !== null) {
        params.append('employee_name', this.searchname)
      }
      if (this.searchEmpNo !== null){
        params.append('employee_number', this.searchEmpNo)
      }

      params.append('subsidiary_id', this.subId)

      if (this.roles.selectedRole === 5){
        params.append('hod_evaluation', this.grade)
      }

      if (this.roles.selectedRole === 1){
        params.append('final_evaluation', this.grade)
      }

      // console.log('sini', this.selectedWork, this.selectedCategory, this.selectedSub, this.selectedDepartment)

      params.append('page', this.currentPage)

      params.append('user_status', 'active')

      // params.forEach(x => {
      //   console.log(x)
      // })

      // eslint-disable-next-line no-restricted-syntax
      // for (const pair of params.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      this.$axios.post(`${this.$baseUrl}${url}`, params).then(response => {
        // console.log('senarai pekerja', response)
        this.showReport = true
        // this.getAllApproveList()
        const summaryReport = response.data.data
        const paging = response.data

        this.from = paging.from
        this.to = paging.to
        this.rows = paging.total
        this.perPage = paging.per_page
        this.lastPage = paging.last_page
        this.currIdx = this.currentPage
        this.isInternal = response.data.internal_industry_status === 'enable'

        // console.log('summ report', paging)
        if (summaryReport.length === 0) {
          this.isNextData = false
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$i18n.t(`No more data`)}.`,
                icon: "CheckCircleIcon",
                variant: "info",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
        this.apiData = summaryReport
        // console.log("hereee", this.apiData)
        // setTimeout(() => {
        //   // this.show = false
        //   this.alterData(search)
        //   console.log("timeee")
        // }, 2000)

        this.alterData(search)
        // this.show = false
      }).catch(() => {
        // console.log(error.message)
        this.show = false
      })
    },
    getWorkLocation(){
      let a

      if (this.roles.selectedRole === 1){
        a = this.selectedSub
      } else {
        a = this.userDetails.business_unit
      }
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${a}`).then(res => {
        const work = res.data.data
        this.WorkList = work.map(x => ({
          text: x.name,
          value: x.id,
        }))
      })
    },
    getData(){
      this.getAllDepartment()
      this.getWorkLocation()
      if (this.selectedWork !== null){
        this.selectedWork = null
      }
      if (this.selectedDepartment !== null){
        this.selectedDepartment = null
      }
    },
    getAllSubsidiary() {
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/subsidiary/getAll`,
      }

      this.$axios(config)
        .then(response => {
          const result = response.data
          if (result.success) {
            const subs = result.data.subsidiaries

            this.subList = subs.map(sub => ({
              text: sub.subsidiary_name,
              value: sub.id,
            }))
          }
        })
        .catch(() => {
          // console.log(error)
        })
    },
    getAllDepartment() {
      let a

      if (this.roles.selectedRole === 1){
        a = this.selectedSub
      } else {
        a = this.userDetails.business_unit
      }
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${a}`,
      }

      this.$axios(config)
        .then(response => {
          this.departmentList = []
          // this.departmentList.push({
          //   text: 'Choose Department',
          //   value: null,
          //   disabled: true,
          // })
          const result = response.data
          if (result.success) {
            const depts = result.data

            this.departmentList = depts.map(dep => ({
              text: dep.name,
              value: dep.id,
            }))
          }
        })
        .catch(() => {
          // console.log(error)
        })
    },

    alterData(search) {
      const allTrainingScore = []
      if (this.allColumnField.length > 7) {
        this.allColumnField.splice(6, (this.allColumnField.length - 5))
      }
      // console.log('kra col', this.apiData.)
      // console.log(allTrainingScore)
      // eslint-disable-next-line no-plusplus

      allTrainingScore.push({
        key: `project_no`,
        label: `Project Management`,
        sortable: true,
        class: 'text-center',
      })

      allTrainingScore.push({
        key: `total_score`,
        label: `Total Score`,
        sortable: true,
        class: 'text-center',
      })
      allTrainingScore.push({
        key: `performance_grade`,
        label: `Performance Grade`,
        sortable: true,
        class: 'text-center',
      })
      allTrainingScore.push({
        key: `hod_evaluation`,
        label: `HOD Evaluation`,
        sortable: true,
        class: 'text-center',
      })
      if (this.roles.selectedRole === 1 || this.roles.selectedRole === 6){
        allTrainingScore.push({
          key: `committee_evaluation`,
          label: `Committee Evaluation`,
          class: 'text-center',
        })
      }

      allTrainingScore.push({
        key: `comment`,
        label: `Comment`,
        class: 'text-center',
      })
      allTrainingScore.push({
        key: 'appraiser_comment',
        label: 'Supervisor Comment',
        class: 'text-center',
      })

      if (!this.roles.isExecutive) {
        allTrainingScore.push({
          key: 'hod_comment',
          label: 'HOD Comment',
          class: 'text-center',
        })
      }

      // console.log('column', this.allColumnField)
      // console.log('training score before splice', allTrainingScore)
      if (this.allColumnField.length < 7) {
        // console.log('col', this.allColumnField)
        this.allColumnField.splice(7, 0, ...allTrainingScore)
      }

      // console.log('column after splice', this.allColumnField)

      this.columns = []

      this.allColumnField.forEach(a => {
        this.columns.push({
          label: a.label,
          field: a.key,
        })
      })

      // eslint-disable-next-line no-unused-vars
      // this.finalData = []
      if (search === 'search') {
        this.finalData = []
      }

      // console.log('apidata', this.apiData)

      this.apiData.forEach(item => {
        const allTraining = {}
        // console.log('kra data', item.jd_kra_data)

        // console.log('job task score', item.appraiser_comment)

        this.finalData.push(
          {
            performance_id: item.performance_id,
            employee_number: item.employee_number,
            employee_name: item.employee_name,
            user_id: item.user_id,
            category: item.category,
            grade: item.grade,
            work: item.work_location[0].toUpperCase() + item.work_location.slice(1).toLowerCase(),
            jd_kra_total: item.jd_kra_total,
            contribution: item.contribution.score,
            total_score: item.total_score,
            performance_grade: item.performance_grade === null ? '-' : item.performance_grade,
            final_evaluation: item.final_evaluation === null ? '-' : item.final_evaluation,
            comment: item.comment === null || item.comment.comment === null ? '-' : item.comment.comment,
            hod_comment: item.hod_comment === null ? '-' : item.hod_comment.comment,
            appraiser_comment: item.appraiser_comment === null || item.appraiser_comment.comment === null ? '-' : item.appraiser_comment.comment,
            approved_at: item.performance_approved_at,
            action: null,
            jd_kra_status: item.jd_kra_status,
            jd_kra_data: item.jd_kra_data,
            status: item.approval_status.message,
            approval_status: item.approval_status,
            subsidiary_id: item.subsidiary_id,
            grade_set: item.grade_set,
            department_id: item.department_id,
            role: item.role,
            hod_evaluation: item.hod_evaluation,
            project_no: item.total_project_management,
            cycle_year: item.year,
            // subsidiary_id: item.business_unit,
            // jd: this.selectedCategory === 'Non Executive' && this.jdkraSetting === 0 ? item.jd_kra_total : null,
            // kra: kraData,

            ...allTraining,
          },
        )
        // console.log('all data', this.finalData)
      })
      this.show = false
    },
    viewHistory() {
      if (this.roles.isTopManagement && this.division !== null){
        this.$router.push({ name: 'division-history-performance', params: { division: this.division } })
      } else {
        this.$router.push({ name: 'history-performance', params: { division: this.division } })
      }
    },

    reloadTable(){
      this.$emit('reload')
    },
  },
}
</script>
<style scoped>
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>
<style type="text/css">
    .popover{
        max-width:600px;
    }
</style>
<style>
.table thead th {
    font-size: 1rem !important;
}
</style>
